import { callWithNuxt } from 'nuxt/app'

import { useAuthStore } from '@store/authStore'
import { useUserStore } from '@store/userStore'

export default defineNuxtRouteMiddleware(async (to) => {
  // https://github.com/nuxt/nuxt/issues/25084
  if (useError().value && process.server) return

  const nuxtApp = useNuxtApp()
  const { hasRefreshToken } = useAuthStore()
  const { loadUser } = useUserStore()
  const { user } = storeToRefs(useUserStore())
  const pageNames = ['signin', 'signup', 'reset']
  const isNeedAuth = to.meta.needAuth

  if (pageNames.includes(to.name) && user.value) {
    return callWithNuxt(nuxtApp, () => navigateTo({ name: 'user-home' }))
  }

  if (hasRefreshToken() && !user.value) {
  }

  if (isNeedAuth && !user.value) {
    return callWithNuxt(nuxtApp, () => navigateTo({ name: 'signin' }))
  }
})
