import type { TSignupSchema } from '@components/signup-form/signup'

interface ITokenPayload {
  exp: number
}

export const useAuthStore = defineStore('authStore', () => {
  const refreshToken = useCookie('refreshToken')

  const auth = reactive({
    accessToken: '',
    accessTokenExpire: 0,
  })

  async function signin(formData: { email: string; password: string }) {
    try {
      const res = await useApi<{ token: string }>(
        false,
        '/api/auth/custom-token/',
        {
          method: 'POST',
          body: {
            username: formData.email,
            password: formData.password,
          },
        }
      )

      const tokenPayload = getTokenPayload(res.token)

      setAuthData({
        accessToken: res.token,
        accessTokenExpire: tokenPayload.exp || 0,
      })
    } catch (e) {
      throw e
    }
  }

  async function signup(formData: TSignupSchema) {
    try {
      const res = await useApi<{ token: string }>(
        false,
        '/api/auth/rest_registration/register/',
        {
          method: 'POST',
          body: formData,
        }
      )
    } catch (e) {
      throw e
    }
  }

  async function signout() {
    try {
    } catch (e) {
      throw e
    }
  }

  async function refresh() {
    try {
      const res = await useApi<{ accessToken: string }>(
        false,
        '/api/auth/token/refresh/',
        {
          method: 'POST',
          headers: useRequestHeaders(['cookie']),
        }
      )

      const tokenPayload = getTokenPayload(res.accessToken)

      setAuthData({
        accessToken: res.accessToken,
        accessTokenExpire: tokenPayload.exp || 0,
      })
    } catch (e) {
      throw e
    }
  }

  function getBearer() {
    return `Bearer ${auth.accessToken}`
  }

  function getTokenPayload(token: string): ITokenPayload {
    try {
      if (!token) throw new Error('Access Token is required')

      const splitToken = token.split('.')

      if (!Array.isArray(splitToken) || splitToken.length < 3) {
        throw new Error('Access Token is not JWT')
      }

      const payload = splitToken[1]

      return JSON.parse(atob(payload))
    } catch (e) {
      throw e
    }
  }

  function isAccessTokenExpired(): boolean {
    // Время жизни токена (в секундах) с вычетанием 10 секунд
    const accessTokenExpiresDate = auth.accessTokenExpire - 10
    // Текущее время в секундах
    const nowTime = Math.floor(Date.now() / 1000)

    return accessTokenExpiresDate <= nowTime
  }

  function setAuthData({
    accessToken,
    accessTokenExpire,
  }: {
    accessToken: string
    accessTokenExpire: number
  }): void {
    auth.accessToken = accessToken
    auth.accessTokenExpire = accessTokenExpire

    setRefreshToken('true')
  }

  function resetAuthData(): void {
    auth.accessToken = ''
    auth.accessTokenExpire = 0

    setRefreshToken('')
  }

  function setRefreshToken(status: string) {
    if (!['', 'true'].includes(status)) {
      throw new Error(
        `setRefreshToken: invalid value '${status}', need one of ["", "true"]`
      )
    }

    refreshToken.value = status
  }

  function hasRefreshToken(): boolean {
    return Boolean(refreshToken.value)
  }

  return {
    auth,
    signin,
    signup,
    signout,
    refresh,
    getBearer,
    isAccessTokenExpired,
    hasRefreshToken,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
