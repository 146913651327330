import { callWithNuxt } from 'nuxt/app'
import { useAuthStore } from '@store/authStore'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'

export const useApi = <
  T,
  R extends NitroFetchRequest = NitroFetchRequest,
  O extends NitroFetchOptions<R> = NitroFetchOptions<R>,
>(
  needAuth: boolean = false,
  url: R,
  options?: O
) => {
  const nuxtApp = useNuxtApp()
  const event = useRequestEvent()
  const { getBearer, isAccessTokenExpired, refresh } = useAuthStore()

  return $fetch<T>(url, {
    ...options,
    onRequest({ request, options }) {
      if (needAuth) {
        options.headers = options?.headers
          ? new Headers(options.headers)
          : new Headers()

        options.headers.set('Authorization', getBearer())

        if (isAccessTokenExpired()) {
          return refresh()
            .then(() => {
              options.headers = options?.headers
                ? new Headers(options.headers)
                : new Headers()

              options.headers.set('Authorization', getBearer())
            })
            .catch((e) => Promise.reject(e))
        }
      }
    },
    onResponse({ request, response }) {
      const cookies = response.headers.getSetCookie()

      if (cookies.length) {
        cookies.forEach((cookie) => {
          if (event) {
            appendResponseHeader(event, 'set-cookie', cookie)
          }
        })
      }
    },
  })
}
