<script lang="ts" setup>
import Button from '@components/ui/button/index.vue'

import type { NuxtError } from 'nuxt/app'

const props = defineProps({
  error: Object as () => NuxtError,
})
const { error } = toRefs(props)
const { t } = useI18n()
const localePath = useLocalePath()
const onClearError = () => clearError({ redirect: '/' })
</script>

<template>
  <NuxtLayout name="default">
    <div class="error">
      <div class="error__status">
        <NuxtImg
          class="error__status-image"
          width="345"
          height="257"
          :src="localePath('/error.svg')"
        />

        <div class="error__status-text">{{ error?.statusCode || 500 }}</div>
      </div>

      <div class="error__message">
        {{ error?.message || t('errors.unknown') }}
      </div>

      <Button type="primary" @click="onClearError">{{
        t('actions.back')
      }}</Button>
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.error {
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 32px;

  &__status {
    position: relative;

    display: flex;
    flex-direction: column;

    &-text {
      text-align: center;

      color: var(--c-primary-default);

      font-size: 86px;
      font-weight: 800;
    }
  }

  &__message {
    color: var(--c-grayscale-white);

    @include t-desktop-text-medium;
  }
}
</style>
