export const buttonProps = {
  type: {
    type: String as PropType<
      'primary' | 'secondary' | 'subtle' | 'text' | 'icon'
    >,
    default: 'primary',
  },
  native_type: {
    type: String as PropType<'button' | 'submit' | 'reset' | undefined>,
    default: 'button',
  },
  size: {
    type: String as PropType<'large' | 'medium' | 'small'>,
    default: 'medium',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
} as const

export type TButtonProps = ExtractPropTypes<typeof buttonProps>
