import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as resetx71D5jr26DMeta } from "/usr/src/nuxt-app/pages/reset.vue?macro=true";
import { default as signindVFe89Mb1OMeta } from "/usr/src/nuxt-app/pages/signin.vue?macro=true";
import { default as signuppd0Tj8SUdWMeta } from "/usr/src/nuxt-app/pages/signup.vue?macro=true";
import { default as index9Y8cICqHnwMeta } from "/usr/src/nuxt-app/pages/user/index.vue?macro=true";
import { default as payments0At4AxuL1HMeta } from "/usr/src/nuxt-app/pages/user/payments.vue?macro=true";
import { default as settingN7nxa2QYjyMeta } from "/usr/src/nuxt-app/pages/user/setting.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/src/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "reset___ru",
    path: "/ru/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: "reset___en",
    path: "/en/reset",
    component: () => import("/usr/src/nuxt-app/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: "signin___ru",
    path: "/ru/signin",
    component: () => import("/usr/src/nuxt-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signin___en",
    path: "/en/signin",
    component: () => import("/usr/src/nuxt-app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "signup___ru",
    path: "/ru/signup",
    component: () => import("/usr/src/nuxt-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "signup___en",
    path: "/en/signup",
    component: () => import("/usr/src/nuxt-app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "user___ru",
    path: "/ru/user",
    meta: index9Y8cICqHnwMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user___en",
    path: "/en/user",
    meta: index9Y8cICqHnwMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-payments___ru",
    path: "/ru/user/payments",
    meta: payments0At4AxuL1HMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/payments.vue").then(m => m.default || m)
  },
  {
    name: "user-payments___en",
    path: "/en/user/payments",
    meta: payments0At4AxuL1HMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/payments.vue").then(m => m.default || m)
  },
  {
    name: "user-setting___ru",
    path: "/ru/user/setting",
    meta: settingN7nxa2QYjyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/setting.vue").then(m => m.default || m)
  },
  {
    name: "user-setting___en",
    path: "/en/user/setting",
    meta: settingN7nxa2QYjyMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/user/setting.vue").then(m => m.default || m)
  }
]