<script lang="ts" setup>
import { buttonProps } from './button'
import { useButton } from './use-button'

const props = defineProps(buttonProps)
const { native_type, loading, disabled } = toRefs(props)
const { buttonCls } = useButton(props)
</script>

<template>
  <button
    class="button"
    :type="native_type"
    :class="buttonCls"
    :disabled="loading || disabled"
  >
    <span class="button__text">
      <slot />
    </span>

    <span class="button__loader"></span>
  </button>
</template>

<style lang="scss">
:root {
  --button-border-radius: 40px;
}

@keyframes rotate_button_loading {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate_button_loading {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.button {
  position: relative;

  display: inline-flex;
  align-items: center;

  border: none;
  border-radius: var(--button-border-radius);
  background: none;

  &__text {
    visibility: visible;
  }

  &--loading &__text {
    visibility: hidden;
  }

  &__loader {
    position: absolute;
    left: calc(50% - 10px);

    display: flex;
    visibility: hidden;

    width: 20px;
    height: 20px;

    animation: rotate_button_loading 1s linear infinite;

    border-radius: 50%;

    &::before {
      position: absolute;

      box-sizing: border-box;

      content: '';
      animation: animate_button_loading 2s linear infinite;

      border: 2px solid var(--c-grayscale-white);
      border-radius: 50%;

      inset: 0px;
    }
  }

  &--loading &__loader {
    visibility: visible;
  }

  &--primary {
    color: var(--c-grayscale-background);
    background: var(--c-primary-default);

    &:hover {
      background: var(--c-primary-dark);
    }
  }

  &--loading.button--primary:hover {
    background: var(--c-primary-default);
  }

  &--secondary {
    color: var(--c-primary-default);
    border: 2px solid var(--c-primary-default);

    &:hover {
      color: var(--c-primary-dark);
      border: 2px solid var(--c-primary-dark);
    }
  }

  &--loading.button--secondary &__loader::before {
    border-color: var(--c-primary-default);
  }
  &--loading.button--secondary:hover {
    border: 2px solid var(--c-primary-default);
    background: transparent;
  }

  &--subtle {
    color: var(--c-primary-default);
    border: 2px solid var(--c-grayscale-line);
    background: none;

    &:hover {
      color: var(--c-primary-dark);
    }
  }

  &--loading.button--subtle &__loader::before {
    border-color: var(--c-primary-default);
  }
  &--loading.button--subtle:hover {
    background: transparent;
  }

  &--text {
  }

  &--icon {
  }

  &.size-large {
    padding: 18px 71px;

    @include t-desktop-link-small;
  }

  &.size-medium {
    padding: 14px 71px;

    @include t-desktop-link-small;
  }

  &.size-small {
    padding: 8px 35px;

    @include t-desktop-link-x-small;
  }
}

</style>
