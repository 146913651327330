import type { IUser } from '@type/user'

export const useUserStore = defineStore('userStore', () => {
  const user = ref<IUser | undefined>(undefined)

  async function loadUser() {
    try {
    } catch (e) {
      throw e
    }
  }

  return {
    user,
    loadUser,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
