import type { TButtonProps } from './button'

export const useButton = (props: TButtonProps) => {
  const { type, size, loading, disabled } = toRefs(props)

  const buttonCls = computed(() => {
    const cls = [`button--${type.value}`, `size-${size.value}`]

    if (loading.value) {
      cls.push('button--loading')
    }

    if (disabled.value) {
      cls.push('button--disabled')
    }

    return cls
  })

  return { buttonCls }
}
